import React from "react"
import { Link } from "gatsby"

export default ({ to, color, children, name, newWindow = false }) =>
  newWindow ? (
    <a href={to} style={{ textDecoration: "none" }} target="_blank">
      <h3 style={{ color }}>{name}</h3>
      <p style={{ color: "black" }}>{children}</p>
    </a>
  ) : (
    <Link to={to} style={{ textDecoration: "none" }}>
      <h3 style={{ color }}>{name}</h3>
      <p style={{ color: "black" }}>{children}</p>
    </Link>
  )
