import React from "react"
import { Link } from "gatsby"

import MainLink from "../components/MainLink"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Printables = ({}) => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <div className="printable-contents">
      <p>
        These are things designed to be printed with a standard, perhaps black
        and white, printer. You will likely need to adjust the scaling level
        manually to get good results. My main project website is{" "}
        <a href="https://www.amimetic.co.uk">Amimetic</a>.
      </p>

      <MainLink to="/habit-streak" color="#444444" name="Habit Streak Paper">
        <p>
          I've made various productivity apps around the idea of a habit streak,
          this is the paper based version
        </p>
      </MainLink>

      <MainLink to="/stationery" color="#444444" name="Custom Stationery">
        <p>Exploring making custom stationery with HTML, CSS and SVG.</p>
      </MainLink>
    </div>
  </Layout>
)

export default Printables
